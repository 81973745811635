<template>
  <div class="d-flex align-items-center volume-container">
    <button
      class="btn btn-dark btn-flat btn-volume me-2"
      @click="toggleMute">
      <font-awesome-icon :icon="['fas', currentVolumeIcon]" fixed-width></font-awesome-icon>
    </button>

    <!-- Volume -->
    <div class="volume-wrapper d-none d-sm-block">
      <div
        class="volume"
        @mousemove="volumeMove"
        @touchmove="volumeMove"
        @mouseleave="setSliderDown(false)"
      >
        <div class="volumeBarFull bar" :style="{ width: barWidthString }"></div>
        <div class="volumeBarEmpty bar" @click="volumeClick"></div>
        <div
          class="volumeSliderBtn"
          :style="{ left: sliderBtnLeft }"
          @mousedown="setSliderDown(true)"
          @touchstart="setSliderDown(true)"
          @mouseup="setSliderDown(false)"
          @touchend="setSliderDown(false)"
          ></div>
      </div>
    </div>
  </div>
</template>

<script>

import player from '@classes/player'


export default {
  name: 'VolumeSlider',
  props: {
  },
  data() {
    return {
      muted: false,
      barWidthString: '',
      sliderBtnLeft: '',
      sliderDown: false,
      initialVolume: 1.0,
      currentVolume: 1.0,
    }
  },
  computed: {
    currentVolumeIcon() {
      if (this.muted || this.currentVolume < 0.05) {
        return 'volume-mute'

      } else if (this.currentVolume >= 0.5) {
        return 'volume-up'

      } else if (this.currentVolume < 0.5 && this.currentVolume >= 0.25) {
        return 'volume-down'

      } else if (this.currentVolume < 0.25 && this.currentVolume >= 0.05) {
        return 'volume-off'

      }

    },
  },
  created() {
    this.setVolume(this.initialVolume)

    window.addEventListener('resize', this.setSliderPosition)
    document.addEventListener('mouseleave', () => {
      this.setSliderDown(false)
    })

  },
  methods: {
    logVolume(volume) {
      // convert to 10-log scale
      const powFactor = 10
      return ( Math.pow(powFactor, volume) - 1 ) / ( powFactor - 1 )
    },
    setVolume(val) {
      this.currentVolume = val
      player.setVolume(this.logVolume(val))

      this.setSliderPosition(val)

    },
    setSliderPosition(val) {
      const barWidth = (val * 90) / 100
      this.barWidthString = (barWidth * 100) + '%'
      // this.sliderBtnLeft = (window.innerWidth * barWidth + window.innerWidth * 0.05 - 25) + 'px'
      this.sliderBtnLeft = 'calc(' + (barWidth * 100) + '% - .5rem)'

    },
    toggleMute() {
      this.muted = !this.muted
      player.mute(this.muted)

    },
    volumeClick(event) {
      this.setVolume(event.layerX / parseFloat(event.target.scrollWidth))

    },
    setSliderDown(value) {
      this.sliderDown = value

    },
    volumeMove(event) {
      if (this.sliderDown) {
        const x = event.clientX || event.touches[0].clientX;
        // const startX = window.innerWidth * 0.05;
        const volumeEl = document.querySelector('.volume')
        const startX = volumeEl.getBoundingClientRect().x
        const layerX = x - startX;
        const barEmpty = document.querySelector('.volumeBarEmpty')
        const per = Math.min(1, Math.max(0, layerX / parseFloat(barEmpty.scrollWidth)));
        this.setVolume(per);
      }
    },
  }
}

</script>

<style lang="scss">

  .volume-container {
    align-items: center;
    height: 100%;
  }



  /* .btn-volume {
    color: #555;
  }

  .dark-mode .btn-volume {
    color: #dbdbdb;
  }
  */

  .btn-volume {
    color: rgba(255, 255, 255, 0.9);
    height: 100%;
    width: 4rem;
  }

  /* Volume */
  .volume-wrapper {
    width: 8rem;
    height: 100%;
  }

  .volume {
    position: absolute;
    width: 8rem;
    height: 4rem;
    // background-color: rgba(0, 0, 0, 0.2);
    /*border: 1px solid #dbdbdb;
    border-left: none;
    border-radius: 0 4px 4px 0; */
    touch-action: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .bar {
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    width: 100%;
    height: 4px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.6);
    // background-color: rgba(50, 50, 50, 0.9);
  }
/*
  .dark-mode .bar {
    background-color: rgba(255, 255, 255, 0.9);
  }
*/

  .volumeBarEmpty {
    width: 90%;
    margin-left: 0;
    opacity: 0.5;
    box-shadow: none;
    cursor: pointer;
  }
  .volumeBarFull {
    margin-left: 0;
    width: 90%;
  }
  .volumeSliderBtn {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    margin: -.5rem auto;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.33);
    border-radius: .5rem;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1);
    // background-color: rgba(50, 50, 50, 1);
  }

  /* .dark-mode .volumeSliderBtn {
    background-color: rgba(250, 250, 250, 1);
  } */

</style>
