class Sound {
  constructor(player, filename, events) {
    this.player = player
    this.filename = filename
    this.events = events || {}

    this.buffer = null
    this.audioBufferSourceNode = null

    this.playing = false
    this.paused = false

    this.duration = 0
    this._seek = 0
    this._startedAt = 0

    this.gainNode = player.ctx.createGain()
    this.gainNode.gain.setValueAtTime(player.volume, player.ctx.currentTime)
    this.gainNode.paused = true
    this.gainNode.connect(player.masterGain)

  }

  setBuffer(buffer) {
    this.buffer = buffer
    this.duration = buffer.duration
  }

  play() {
    if (this.player.ctx.state === 'suspended') {
        this.player.ctx.resume();
    }

    this._startedAt = this.player.ctx.currentTime
    this.audioBufferSourceNode = this.player.ctx.createBufferSource()
    this.audioBufferSourceNode.buffer = this.buffer
    this.audioBufferSourceNode.connect(this.gainNode)

    this.audioBufferSourceNode.onended = () => {
      this.stop()

      if (this.events.onend) {
        this.events.onend()
      }

    }

    this.audioBufferSourceNode.start(0, this._seek, 86400)

    this.playing = true

    if (this.events.onplay) {
      this.events.onplay()
    }

  }

  pause() {
    if (!this.playing) {
      return
    }


    const seek = this._seek + (this.player.ctx.currentTime - this._startedAt)

    this.stop()

    this._seek = seek
    this._startedAt = this.player.ctx.currentTime

    if (this.events.onpause) {
      this.events.onpause()
    }

  }

  stop() {
    if (this.audioBufferSourceNode) {
      this.audioBufferSourceNode.stop(0)

      // not needed but reset "properly"
      this.audioBufferSourceNode.onended = null
      this.audioBufferSourceNode.disconnect(0)
      this.audioBufferSourceNode = null
    }

    this.playing = false
    this._seek = 0

    if (this.events.onstop) {
      this.events.onstop()
    }

  }

  seek(value) {
    if (value) {
      let wasPlaying = false
      if (this.playing) {
        this.pause()
        wasPlaying = true
      }

      this._seek = value

      if (wasPlaying) {
        this.play()
      } else {
        this._startedAt = this.player.ctx.currentTime
      }


      if (this.events.onseek) {
        this.events.onseek()
      }

    } else {
      return this._seek + this.player.ctx.currentTime - this._startedAt

    }

  }

}

class Player {
  constructor() {
    this.ctx = new AudioContext()
    this.sounds = []

    this.volume = null
    this.muted = false

    this.masterGain = this.ctx.createGain()
    this.masterGain.connect(this.ctx.destination)

    this.boundUnlockContext = this.unlockContext.bind(this)
    window.addEventListener('click', this.boundUnlockContext)
  }

  unlockContext() {
    if (this.ctx.state == 'suspended') {
      this.ctx.resume()
    }
    window.removeEventListener('click', this.boundUnlockContext)
  }

  async addSound(config) {
    const sound = new Sound(this, config.filename, config.events)
    const buffer = await this.ctx.decodeAudioData(config.arrayBuffer)
    sound.setBuffer(buffer)

    this.sounds.push(sound)

    return sound
  }

  setVolume(vol) {
    vol = parseFloat(vol)

    this.volume = vol

    this.masterGain.gain.setValueAtTime(vol, this.ctx.currentTime)
  }

  mute(value) {
    this.muted = value
    this.masterGain.gain.setValueAtTime(this.muted ? 0 : this.volume, this.ctx.currentTime)
  }

  stop() {
    this.sounds.forEach(s => s.stop())
  }

  removeAll() {
    this.stop()
    this.sounds = []
  }

}

const player = new Player()

export default player
