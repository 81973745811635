<template>
  <div>
    <Header></Header>
    <section class="mt-4">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>
import '../styles.scss'

import Header from './header'

export default {
  components: { Header },
  data() {
    return {
      test: 'rofl',
    }
  },
  computed: {
    theme() {
      return this.$store.getters.theme
    }
  }
}

</script>
