import Vue from 'vue'
import VueRouter from 'vue-router'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faPlay,
    faPause,
    faVolumeUp,
    faVolumeDown,
    faVolumeOff,
    faVolumeMute,
    faDownload,
    faUpload,
    faAdjust,
    faMoon,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap'


import App from "./App.vue";
import routes from './routes'
import store from './store'

library.add(faPlay)
library.add(faPause)
// library.add(faVolume)
library.add(faVolumeUp)
library.add(faVolumeDown)
library.add(faVolumeOff)
library.add(faVolumeMute)
library.add(faDownload)
library.add(faUpload)
library.add(faAdjust)
library.add(faMoon)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes,
})

const app = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

export default app
