<script>

import VolumeSlider from './volume-slider'
import DarkModeSwitch from './dark-mode-switch'

import store from '../store'


export default {
  components: { VolumeSlider, DarkModeSwitch },
  computed: {
    dark() {
      return store.getters['theme'] === 'dark'
    }
  }
}

</script>

<template>
  <header>
    <div class="container">
      <div class="card px-5" style="border-bottom: none !important;">
        <nav class="navbar navbar-expand-lg order-1" :class="dark ? 'navbar-dark' : 'navbar-light'">
          <div class="d-flex align-items-center justify-content-between">
            <button
              class="navbar-toggler me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-toggle"
              aria-controls="navbar-toggle"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <a class="navbar-brand" href="/">Matz Radloff</a>

            <div class="flex-grow-1"></div>

          </div>

          <div class="collapse navbar-collapse me-auto order-4 order-lg-2" id="navbar-toggle">
            <ul class="navbar-nav">
              <!--<router-link :to="{ name: 'cv' }" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <li class="nav-item" :class="[isExactActive && 'active']">
                  <a class="nav-link" :href="href" @click="navigate">CV</a>
                </li>
              </router-link>-->
              <router-link :to="{ name: 'music' }" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <li class="nav-item" :class="[isActive && 'active']">
                  <a class="nav-link" :href="href" @click="navigate">Music</a>
                </li>
              </router-link>
              <router-link :to="{ name: 'samples' }" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <li class="nav-item" :class="[isExactActive && 'active']">
                  <a class="nav-link" :href="href" @click="navigate">Samples</a>
                </li>
              </router-link>
              <router-link :to="{ name: 'waveformAnalyzer' }" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <li class="nav-item" :class="[isExactActive && 'active']">
                  <a class="nav-link" :href="href" @click="navigate">Waveform Analyzer</a>
                </li>
              </router-link>
              <router-link :to="{ name: 'waveformRenderer' }" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <li class="nav-item" :class="[isExactActive && 'active']">
                  <a class="nav-link" :href="href" @click="navigate">Waveform Renderer</a>
                </li>
              </router-link>
              <router-link :to="{ name: 'backgroundGenerator' }" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <li class="nav-item" :class="[isExactActive && 'active']">
                  <a class="nav-link" :href="href" @click="navigate">Background Generator</a>
                </li>
              </router-link>
              <!--<router-link :to="{ name: 'speedtest' }" v-slot="{ href, route, navigate, isActive, isExactActive }">
                <li class="nav-item" :class="[isExactActive && 'active']">
                  <a class="nav-link" :href="href" @click="navigate">Speedtest</a>
                </li>
              </router-link>-->
            </ul>
          </div>

          <div class="flex-grow-1 order-2 order-lg-3"></div>

          <div class="d-flex align-items-center order-3 order-lg-4" style="height: 100%">

            <VolumeSlider class="me-2"></VolumeSlider>
            <DarkModeSwitch></DarkModeSwitch>

          </div>
        </nav>
      </div>

      <div class="border-green"></div>

    </div>

  </header>
</template>
