export default [
    {
        name: 'test',
        path: '/test',
        component: () => import('./components/test'),
    },
    {
        name: 'music',
        path: '/music',
        alias: '/',
        component: () => import('./components/music'),
    },
    {
        name: 'music-detail',
        path: '/music/:filename',
        component: () => import('./components/music-detail'),
        props: ($route) => ({ filename: $route.params.filename }),
    },
    {
        name: 'samples',
        path: '/samples',
        component: () => import('./components/samples'),
    },
    /* {
        name: 'cv',
        path: '/cv',
        alias: '/',
        component: () => import('./components/cv'),
    }, */
    {
        name: 'projects',
        path: '/projects',
        component: () => import('./components/projects'),
    },
    {
        name: 'waveformAnalyzer',
        path: '/waveform-analyzer',
        component: () => import('./components/waveform-analyzer'),
    },
    {
        name: 'waveformRenderer',
        path: '/waveform-renderer',
        component: () => import('./components/waveform-render'),
    },
    {
        name: 'backgroundGenerator',
        path: '/background-generator',
        component: () => import('./components/background-generator'),
    },
    {
        name: 'speedtest',
        path: '/speedtest',
        component: () => import('./components/speedtest'),
    },
]
