<template>
  <div class="">
    <Layout></Layout>
  </div>
</template>

<script>

import Layout from './components/layout'

export default {
  data() {
    return {
    };
  },
  components: { Layout },
  beforeMount() {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches
    let savedTheme = localStorage.getItem("theme")
    if (savedTheme === undefined) {
      localStorage.setItem('theme', prefersDarkScheme ? 'dark' : 'light')
      savedTheme = prefersDarkScheme
    }

    if (savedTheme === 'dark') {
      document.body.classList.toggle("dark-mode");
    }

  },
  created() {
  }
}

</script>
