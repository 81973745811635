import Vue from 'vue'
import Vuex from 'vuex'

import app from './main'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    songs: [
      {
        filename: 'spirit_RC2',
        name: 'Spirit',
        progress: 0,
      },
      {
        filename: 't35_RC1',
        name: 'Quick and Dirty',
        progress: 0,
      },
      {
        filename: 'a_song_5_RC4',
        name: 'DnB mit Mai',
        progress: 0,
      },
      {
        filename: 'nad_3_RC6',
        name: 'Why',
        progress: 0,
      },
      {
        filename: 'nad_RC5',
        name: 'New Beginnings',
        progress: 0,
      },
      {
        filename: 'hss_RC4',
        name: 'Resonance',
        progress: 0,
      },
      {
        filename: 'arp2_RC7',
        name: 'Calm and Happy',
        progress: 0,
      },
      {
        filename: 'mig_RC9',
        name: 'Why We Fight',
        progress: 0,
      },
      {
        filename: 'dnb3_RC4',
        name: '39 Reasons',
        progress: 0,
      },
      {
        filename: 'fresh_start_RC5',
        name: 'Fresh Start',
        progress: 0,
      },
      {
        filename: 'bf2_RC4',
        name: 'Harmonics',
        progress: 0,
      },
      {
        filename: 'choir_dings_3_RC2',
        name: 'Mystery',
        progress: 0,
      },
      {
        filename: 'corona_song_matz_2_RC3',
        name: 'C0v1d',
        progress: 0,
      },
      {
        filename: 'summer_RC5_omg',
        name: 'Summer',
        progress: 0,
      },
      {
        filename: 'ride',
        name: 'Refuzion - Ride (Matz Remix)',
        progress: 0,
      },
      {
        filename: 'y21_4_RC2',
        name: 'No Horse Was Harmed in the Making Of This Track',
        progress: 0,
      },
      {
        filename: 'dirty_brass',
        name: 'Dirty Brass',
        progress: 0,
      },
      {
        filename: 'all_is_found_2',
        name: 'All is Found',
        progress: 0,
      },
      {
        filename: 'song3',
        name: 'Song3',
        progress: 0,
      },
      {
        filename: 'prelude',
        name: 'Prelude',
        progress: 0,
      },
      {
        filename: 'poco_loco_4',
        name: 'Poco Loco',
        progress: 0,
      },
      /* {
        filename: 'sine_1k_1m',
        name: 'Sine',
        progress: 0,
      }, */
    ],
    samples: [
      {
        filename: 'bkw_kick/e',
        download_file: 'audio/bkw_kick/bkw_kick.zip',
        download_name: 'Download as ZIP',
        name: 'bkw_kick',
      },
    ],
    theme: localStorage.getItem('theme') || 'light',
  },
  getters: {
    songs(state) {
      return state.songs
    },
    song(state) {
      return (filename) => {
        let song = state.songs.find(s => s.filename === filename)
        if (!song) {
          song = state.samples.find(s => s.filename === filename)
        }
        return song
      }
    },
    theme(state) {
      return state.theme
    },
    samples(state) {
      return state.samples
    },
  },
  mutations: {
    SET_SONG_DATA(state, { filename, data, extension }) {
      let song = state.songs.find(s => s.filename === filename)
      if (!song) {
        song = state.samples.find(s => s.filename === filename)
      }
      if (!song.data) {
        song.data = {}
      }
      song.data[extension] = data
    },
    SET_SONG_FILEPATH(state, { filename, filepath }) {
      let song = state.songs.find(s => s.filename === filename)
      if (!song) {
        song = state.samples.find(s => s.filename === filename)
      }
      song.filepath = filepath
    },
    SET_SONG_PROGRESS(state, { filename, progress }) {
      let song = state.songs.find(s => s.filename === filename)
      if (!song) {
        song = state.samples.find(s => s.filename === filename)
      }
      song.progress = progress
    },
    SET_THEME(state, { theme }) {
      state.theme = theme
    },
  },
  actions: {
    setTheme({ commit }, { theme }) {
      commit('SET_THEME', { theme })

      if (app) {
        app.$emit('setTheme', theme)
      }

    },
    async setProgress({ commit }, { filename, progress }) {
      commit('SET_SONG_PROGRESS', { filename, progress })
    },
    async loadSongData({ state, commit }, { filename, extension }) {
      let song = state.songs.find(s => s.filename === filename)

      // check if its a sample
      if (!song) {
        song = state.samples.find(s => s.filename === filename)
      }

      if (song && song.data && song.data[extension]) {
        return song.data[extension]
      }

      const filepath = require('../public/audio/' + filename + '.' + extension)
      commit('SET_SONG_FILEPATH', { filename, filepath })

      const response = await fetch(filepath)

      const reader = response.body.getReader()
      const contentLength = +response.headers.get('Content-Length')

      let receivedLength = 0
      let chunks = []
      let progress

      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          break
        }

        chunks.push(value)
        receivedLength += value.length

        progress = (((receivedLength / contentLength) * 100) || 0)
        commit('SET_SONG_PROGRESS', { filename, progress })

      }

      const arrayBuffer = new ArrayBuffer(receivedLength)
      let chunksAll = new Uint8Array(arrayBuffer)
      let position = 0
      for (let chunk of chunks) {
        chunksAll.set(chunk, position)
        position += chunk.length
      }

      commit('SET_SONG_DATA', { filename, data: arrayBuffer, extension })

    },
  },
})

export default store
