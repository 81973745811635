var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card px-5",staticStyle:{"border-bottom":"none !important"}},[_c('nav',{staticClass:"navbar navbar-expand-lg order-1",class:_vm.dark ? 'navbar-dark' : 'navbar-light'},[_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse me-auto order-4 order-lg-2",attrs:{"id":"navbar-toggle"}},[_c('ul',{staticClass:"navbar-nav"},[_c('router-link',{attrs:{"to":{ name: 'music' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:[isActive && 'active']},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Music")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'samples' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:[isExactActive && 'active']},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Samples")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'waveformAnalyzer' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:[isExactActive && 'active']},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Waveform Analyzer")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'waveformRenderer' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:[isExactActive && 'active']},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Waveform Renderer")])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'backgroundGenerator' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item",class:[isExactActive && 'active']},[_c('a',{staticClass:"nav-link",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Background Generator")])])]}}])})],1)]),_c('div',{staticClass:"flex-grow-1 order-2 order-lg-3"}),_c('div',{staticClass:"d-flex align-items-center order-3 order-lg-4",staticStyle:{"height":"100%"}},[_c('VolumeSlider',{staticClass:"me-2"}),_c('DarkModeSwitch')],1)])]),_c('div',{staticClass:"border-green"})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('button',{staticClass:"navbar-toggler me-2",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbar-toggle","aria-controls":"navbar-toggle","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_vm._v("Matz Radloff")]),_c('div',{staticClass:"flex-grow-1"})])}]

export { render, staticRenderFns }