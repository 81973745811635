<template>
  <div class="d-flex align-items-center dark-mode" style="height: 100%;">
    <button
      class="btn btn-flat"
      style="height: 100%; width: 4rem;"
      @click="toggleCurrentDarkModeValue">
      <font-awesome-icon :icon="icon" fixed-width></font-awesome-icon>
    </button>

  </div>
</template>

<script>

export default {
  name: 'DarkModeSwitch',
  props: {
  },
  data() {
    return {
      theme: null,
    }
  },
  computed: {
    icon() {
      return this.theme === 'dark' ? 'adjust' : 'moon'
    }
  },
  mounted() {
    this.theme = localStorage.getItem('theme') || 'light'
  },
  watch: {
    theme(value) {
      localStorage.setItem('theme', value)
      this.$store.dispatch('setTheme', { theme: value })

      if (value === 'dark') {
        document.body.classList.add("dark-mode");

      } else {
        document.body.classList.remove("dark-mode");

      }
    },
  },
  created() {},
  methods: {
    toggleCurrentDarkModeValue() {
      this.theme = this.theme === 'dark' ? 'light' : 'dark'
    }
  }
}

</script>

<style lang="scss">

</style>
